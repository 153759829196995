<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="名称" prop="name">
      <Input v-model="formData.name" placeholder="请输入名称" />
    </FormItem>
    <FormItem label="编码" prop="code">
      <Input v-model="formData.code" placeholder="请输入编码" />
    </FormItem>
    <FormItem label="所属仓库" prop="storehouseId" v-if="!pStorehouseUnitId">
      <Select v-model="formData.storehouseId">
        <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.typeData.label }} - {{ item.name }}</Option>
      </Select>
    </FormItem>
    <FormItem label="位置" prop="address">
      <Input v-model="formData.address" placeholder="请输入位置" />
    </FormItem>
    <FormItem label="容积(L)" prop="volume">
      <InputNumber v-model="formData.volume" controls-outside />
    </FormItem>
    <FormItem label="承重(kg)" prop="bearing">
      <InputNumber v-model="formData.bearing" controls-outside />
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
export function getDefault () {
  return {
    id: null,
    name: null,
    code: null,
    storehouseId: null,
    pStorehouseUnitId: null,
    address: null,
    volume: null,
    bearing: null,
    remark: null
  }
}

const rules = Object.freeze({
  name: [
    { required: true, message: '请输入名称' }
  ],
  storehouseId: [
    { required: true, message: '请选择所属仓库' }
  ]
})

export default {
  props: {
    data: { type: Object, default: null },
    pStorehouseUnitId: { type: Number, default: null },
    storehouseId: { type: Number, default: null }
  },
  data () {
    return {
      formData: this.data || getDefault(),
      rules
    }
  },
  computed: {
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault()
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadStoreHouse')
  }
}
</script>
